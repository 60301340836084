require('../css/app.scss');

let qq = require('fine-uploader/lib/all');
window.qq = qq;
const $ = require('jquery');
window.$ = $;
window.jQuery = $;
window.Tether = require('tether');

import bsCustomFileInput from 'bs-custom-file-input';
window.bsCustomFileInput = bsCustomFileInput;

require('popper.js');
require('bootstrap');
require('mdbootstrap');
require('bootstrap-datepicker');
require('datatables.net');
require('datatables.net-bs4');
require('datatables-responsive');
require('ekko-lightbox');


$(document).ready(function() {
    // console.log('hello');
});
